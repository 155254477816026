import actions from '../store/actions';
import { getCookie, getDecryptedCookie } from './cookie';

const setAuthentication = async (ctx, subdomain) => {
  let authorized_pages = ['/profile/settings', '/mybooking', '/wishlist', '/mypoint'];
  if (ctx.req) {
    // server
    const pathname = ctx.asPath;
    let token_cookie = getCookie('token', ctx.req);
    if (subdomain === 'business') {
      token_cookie = getCookie('token_business', ctx.req);
      authorized_pages = [
        ...authorized_pages,
        '/things-to-do',
        '/search',
        '/experience',
        '/spot',
        '/theme',
        '/event',
        '/campaign',
        '/activity-theme',
      ];
    }
    let is_authorized_page = authorized_pages.some(v => pathname.startsWith(v));
    if (token_cookie) {
      // if has token
      let user_cookie = getDecryptedCookie('user', ctx.req);
      if (subdomain === 'business') {
        user_cookie = getDecryptedCookie('user_business', ctx.req);
      }
      if (typeof user_cookie === 'string') {
        user_cookie = JSON.parse(decodeURIComponent(user_cookie));
      }
      // save in redux store
      ctx.store.dispatch(actions.reauthenticate(token_cookie, user_cookie));
    } else {
      // if don't have token
      if (is_authorized_page) {
        ctx.res.writeHead(302, {
          Location: `/?redirect=${pathname}`,
        });
        ctx.res.end();
      } else if (pathname.indexOf('/affiliate/submit') > -1) {
        ctx.res.writeHead(302, {
          Location: `/affiliate`,
        });
        ctx.res.end();
      }
    }
  }
};
export default setAuthentication;
