const removeUrlParameter = (sParam,  initUrlParams) =>
{
  initUrlParams = initUrlParams.split('?');
  let sSetURL = [];
  if (initUrlParams.length > 1) {
      const sURLVariables = initUrlParams[1].split('&');
      for (let i = 0; i < sURLVariables.length; i++)
      {
          let sParameterName = sURLVariables[i].split('=');
          if (Array.isArray(sParam)) {
            if (sParam.indexOf(sParameterName[0]) < 0) {
              sSetURL.push(sParameterName[0] + '=' + sParameterName[1]);
            }
          } else {
            if (sParameterName[0] !== sParam) {
              sSetURL.push(sParameterName[0] + '=' + sParameterName[1]);
            }
          }
      }
  }
  let sRUrl = '';
  if (sSetURL.length>0) {
      sRUrl += "?"+sSetURL.join('&');
  }
  return sRUrl;
}

export default removeUrlParameter;
