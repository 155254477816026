export default (url) => {
  let index = 0;
  let new_url = url;
  index = url.indexOf('?');
  if(index == -1){
      index = url.indexOf('#');
  }
  if(index != -1){
      new_url = url.substring(0, index);
  }
  return new_url;
}
