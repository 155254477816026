import setAdminAnalytics from './setAdminAnalytics';
import setBasicInfo from './setBasicInfo';
import setSubDomain from './setSubDomain';
import setAuthentication from './setAuthentication';
import setAuthenticationPartner from './setAuthenticationPartner';
import setAuthenticationCompany from './setAuthenticationCompany';
import getSubDomain from '/utils/getSubDomain';
import setDevice from './setDevice';

const initialize = async ctx => {
  if (!ctx) return;
  const subdomain = getSubDomain(ctx);
  await setDevice(ctx);
  await setAdminAnalytics(ctx);
  await setSubDomain(ctx, subdomain);
  await setAuthentication(ctx, subdomain);
  await setAuthenticationPartner(ctx);
  await setAuthenticationCompany(ctx);
  await setBasicInfo(ctx);
};

export default initialize;
